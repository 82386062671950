import axios from 'axios'

import Vue from 'vue'
// process.env.NODE_ENV === 'production' ? '' : ''+

// const baseURL = '/api';

const baseURL = '';


const $toast = Vue.$toast

const defaultSetting = {
  baseURL: baseURL + '/',
  timeout: 30000,
  withCredentials: true,
}






const instance = axios.create(defaultSetting)
instance.interceptors.request.use((req) => {
  return req
})

instance.interceptors.response.use(res => {
  // console.log('ok', res)
  if (res.status === 200) {
    // console.log('ok', res)
    let rstatus = res.data.status
    if(rstatus !== 200){
      if(res.data.message){
        $toast.warning(res.data.message)
      }
    }
  }else{
    let msg = res.data.message
    // console.log('e', res.data)
    if(msg){
      $toast.warning(msg)
    }
  }
  return res.data
}, (err) => {
  const errorResp = err.response
  if (errorResp && errorResp.data) {
    $toast.error(errorResp.data.message)
  }else{
    $toast.error('网络或服务异常!')
  }
  return
  // throw (err)
})

export default instance