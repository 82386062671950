import instance from './instance.js'

import axios from 'axios'

// const baseURL = process.env.NODE_ENV === 'production' ? '' : ''+'/api'

// const baseURL = '/api'

const baseURL = '';

// 获取验证码
export const getYzm = (phone) => instance.get('/api-employment/auth/getVerificationCode', { params: { phone} })
// 登录
export const login = ({ phone, code }) => instance.post('/api-employment/auth/loginByPhoneCode', { phone, code })
// 上传简历
export const uploadInfo = ({ file, name, idCard, categoryId }) => {
  const token = sessionStorage.getItem('token')
  const formData = new FormData()
  formData.append('file', file)
  formData.append('name', name)
  formData.append('idCard', idCard)
  formData.append('categoryId', categoryId)
  return axios.post(baseURL + '/api-employment/resume/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data;charset=utf-8',
      'Authorization': 'Bearer '+ token
    }
  })
}


